'use client';

import { trackGenericEvent } from '@/utils/analytics';
import { Column } from '@/components/Column';
import { GradientButton } from '@/components/GradientButton';
import { Logo } from '@/components/Icon/Logo';
import { ResponsiveContainer } from '@/components/ResponsiveContainer';
import { Row } from '@/components/Row';
import { useAuth, useAuthModal } from '@/contexts';
import { useOnClickOutside, useScreenSize } from '@/hooks';
import classNames from 'classnames';
import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { DropdownMenu } from './components';
import { SessionI } from '@/types/cyclone/requests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCaretDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useCountryContext } from '@/contexts/country';
import { iconArgentina, iconChile, iconUruguay } from '@/static/images';
import Image from 'next/legacy/image';

const LogoButton: React.FC<{ isWhiteNavbar: boolean }> = ({ isWhiteNavbar }) => {
  const router = useRouter();
  const path = usePathname();

  const isActivitiesPath = path?.includes('/actividades');
  return (
    <button
      onClick={() => {
        trackGenericEvent('Button Navbar Item Clicked', { item: 'Logo' });

        if (isActivitiesPath) {
          router.push('/?utm_source=agora&utm_medium=link&utm_campaign=actividades');
        } else router.push('/');
      }}
      className="h-[60px] sm:h-[70px] aspect-square"
    >
      <Logo type="horizontal" width="200%" color={isWhiteNavbar ? '#212121' : '#FFF'} />
    </button>
  );
};

const DesktopMenu: React.FC<{ items: any[]; isLessThanDesktop: boolean }> = ({
  items,
  isLessThanDesktop
}) => {
  if (isLessThanDesktop) return null;
  return (
    <Row className="gap-8">
      {items.map((item) => (
        <button key={item.label} onClick={item.onClick}>
          <span className="hover:underline">{item.label}</span>
        </button>
      ))}
    </Row>
  );
};

const AuthButtons: React.FC<{
  isAuthenticated: boolean;
  showAuthModal: any;
  showDropdownMenu: boolean;
  closeDropdownMenu: () => void;
  toggleDropdown: () => void;
  isWhiteNavbar: boolean;
  handleItemClick: any;
  profileRef: any;
  session: SessionI | null;
  isLessThanDesktop: boolean;
  showMobileMenu: boolean;
  setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  isAuthenticated,
  showAuthModal,
  closeDropdownMenu,
  handleItemClick,
  isWhiteNavbar,
  profileRef,
  showDropdownMenu,
  toggleDropdown,
  session,
  isLessThanDesktop,
  showMobileMenu,
  setShowMobileMenu
}) => {
  const path = usePathname();

  const isActivitiesRoute = path?.includes('/actividades');
  const isAuthModalVendor = isActivitiesRoute ? false : true;

  if (isAuthenticated) {
    return (
      <DropdownMenu
        session={session}
        showDropdownMenu={showDropdownMenu}
        closeDropdownMenu={closeDropdownMenu}
        toggleDropdown={toggleDropdown}
        isWhiteNavbar={isWhiteNavbar}
        handleItemClick={handleItemClick}
        profileRef={profileRef}
      />
    );
  } else {
    if (isLessThanDesktop)
      return (
        <div className="place-self-end self-center">
          <button onClick={() => setShowMobileMenu(!showMobileMenu)}>
            <FontAwesomeIcon icon={showMobileMenu ? faTimes : faBars} />
          </button>
        </div>
      );
    else {
      return (
        <Row align="center" gap={5} className="place-self-end self-center">
          <GradientButton onClick={() => showAuthModal('signin', isAuthModalVendor)} outline>
            <span className="text-blue">Ingresar</span>
          </GradientButton>
          <GradientButton onClick={() => showAuthModal('signup', isAuthModalVendor)}>
            <span>Registrarme</span>
          </GradientButton>
        </Row>
      );
    }
  }
};

const CountryFlag: React.FC = () => {
  const { country, setCountry } = useCountryContext();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const countryOptions = [
    { icon: iconArgentina, value: 'ar', label: 'Argentina' },
    { icon: iconChile, value: 'cl', label: 'Chile' },
    { icon: iconUruguay, value: 'uy', label: 'Uruguay' }
  ];

  const handleCountryChange = (country: string) => {
    setCountry(country);
    setIsDropdownOpen(false);
  };

  return (
    <div className={`dropdown dropdown-end ${isDropdownOpen ? 'dropdown-open' : ''}`}>
      <Row
        align="center"
        tabIndex={0}
        role="button"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="btn min-h-[45px] border-none shadow-none hover:bg-transparent bg-transparent"
      >
        <div className="relative w-6 h-6 rounded-full border">
          <Image
            layout="fill"
            objectFit="cover"
            objectPosition={country === 'ar' ? 'center' : 'left'}
            src={countryOptions.find((option) => option.value === country)?.icon || countryOptions[0].icon}
            alt="country flag"
            className="absolute rounded-full"
          />
        </div>
        <FontAwesomeIcon icon={faCaretDown} />
      </Row>
      {isDropdownOpen && (
        <ul tabIndex={0} className="dropdown-content z-[1] p-4 shadow bg-base-100 rounded-box w-fit">
          {countryOptions.map((option) => (
            <li
              key={option.value}
              className="mb-4 last:mb-0 cursor-pointer"
              onClick={() => handleCountryChange(option.value)}
            >
              <Row align="center" gap={16}>
                <div className="relative w-6 h-6 rounded-full border">
                  <Image
                    layout="fill"
                    objectFit="cover"
                    objectPosition={option.value === 'ar' ? 'center' : 'left'}
                    src={option.icon}
                    alt="country flag"
                    className="absolute rounded-full"
                  />
                </div>
                <span>{option.label}</span>
              </Row>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const MobileMenu: React.FC<{
  items: any[];
  handleAuthAction: any;
  setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ items, handleAuthAction, setShowMobileMenu }) => (
  <div className="fixed inset-x-0 top-[60px] sm:top-[70px] bottom-0 z-50 bg-white opacity-100">
    <div className="absolute inset-0 bg-white opacity-100">
      <div className="mt-16">
        {items.map((item, index) => (
          <button
            key={index}
            className="block w-full text-center py-4 border-b"
            onClick={() => {
              item.onClick();
              setShowMobileMenu(false);
            }}
          >
            <span className="text-lg">{item.label}</span>
          </button>
        ))}
      </div>
      <Column align="center" justify="center" className="px-4 pt-16" gap={16}>
        <GradientButton onClick={() => handleAuthAction('signup')}>
          <span className="text-lg">Registrarme</span>
        </GradientButton>
        <GradientButton onClick={() => handleAuthAction('signin')} outline>
          <span className="text-blue text-lg">Ingresar</span>
        </GradientButton>
      </Column>
    </div>
  </div>
);

export const Navbar: React.FC = () => {
  const router = useRouter();
  const { isAuthenticated, session } = useAuth();
  const path = usePathname();
  const params = useSearchParams();
  const { showAuthModal } = useAuthModal();
  const [showDropdownMenu, setShowDropdownMenu] = useState<boolean>(false);
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const profileRef = useRef<HTMLDivElement | null>(null);
  const { isLessThanDesktop } = useScreenSize();
  const isWhiteNavbar = true;
  const isActivitiesRoute = path?.includes('/actividades');
  const paramGuest = params?.get('guest');
  const isAuthModalVendor = isActivitiesRoute ? false : true;

  const items = useMemo(
    () => [
      {
        label: 'Funcionalidades',
        path: '/funcionalidades',
        analyticsLabel: 'Functionality'
      },
      {
        label: 'Centro de Ayuda',
        path: 'https://ayuda.agora.red/es/',
        analyticsLabel: 'Help Center',
        external: true
      },
      {
        label: 'Sobre Ágora',
        path: '/sobre-nosotros',
        analyticsLabel: 'About Us'
      }
    ],
    []
  );

  const handleNavItemClick = useCallback(
    (item: any) => {
      if (item.external) {
        window.open(item.path, '_blank');
      } else if (item.path) {
        router.push(item.path);
      }
      trackGenericEvent('Button Navbar Item Clicked', { item: item.analyticsLabel });
    },
    [router]
  );

  const handleAuthAction = (action: 'signin' | 'signup') => {
    showAuthModal(action, isAuthModalVendor);
    trackGenericEvent('Button Navbar Item Clicked', { item: action === 'signin' ? 'Sign In' : 'Sign Up' });
    setShowMobileMenu(false);
  };

  const closeDropdownMenu = useCallback(() => {
    setShowDropdownMenu(false);
  }, []);

  const toggleDropdown = useCallback(() => {
    setShowDropdownMenu((prev) => !prev);
  }, []);

  useOnClickOutside(profileRef, closeDropdownMenu);

  const menuItems = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        onClick: () => handleNavItemClick(item)
      })),
    [handleNavItemClick, items]
  );

  return (
    <>
      <header
        className={classNames({
          'fixed top-0 h-[60px] sm:h-[70px] z-20 w-full': true,
          'bg-white shadow-md': isWhiteNavbar,
          'bg-transparent': !isWhiteNavbar
        })}
        style={{
          transition: 'box-shadow 0.3s, background-color 0.3s, color 0.3s',
          boxShadow: isWhiteNavbar ? '0px 4px 4px rgba(0, 0, 0, 0.12)' : 'none'
        }}
      >
        <ResponsiveContainer>
          <Row as="div" className="w-full h-full" align="center" justify="space-between">
            <LogoButton isWhiteNavbar={isWhiteNavbar} />
            {!isActivitiesRoute && <DesktopMenu items={menuItems} isLessThanDesktop={isLessThanDesktop} />}
            <Row align="center" gap={8}>
              <CountryFlag />
              {isActivitiesRoute && paramGuest ? null : (
                <AuthButtons
                  isAuthenticated={isAuthenticated}
                  showAuthModal={showAuthModal}
                  session={session}
                  closeDropdownMenu={closeDropdownMenu}
                  toggleDropdown={toggleDropdown}
                  showDropdownMenu={showDropdownMenu}
                  isWhiteNavbar={isWhiteNavbar}
                  handleItemClick={handleNavItemClick}
                  profileRef={profileRef}
                  isLessThanDesktop={isLessThanDesktop}
                  showMobileMenu={showMobileMenu}
                  setShowMobileMenu={setShowMobileMenu}
                />
              )}
            </Row>
          </Row>
        </ResponsiveContainer>
      </header>
      {showMobileMenu && (
        <MobileMenu
          items={menuItems}
          handleAuthAction={handleAuthAction}
          setShowMobileMenu={setShowMobileMenu}
        />
      )}
    </>
  );
};
