'use client';
import { Column } from '@/components/Column';
import { Logo } from '@/components/Icon/Logo';
import { ResponsiveContainer } from '@/components/ResponsiveContainer';
import { Row } from '@/components/Row';
import { MEDIA_LINKS } from '@/constants';
import { useNavbarItems, useScreenSize } from '@/hooks';
import { Wrapper } from '@/styles/components';
import { theme } from '@/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/navigation';
import React, { FunctionComponent } from 'react';

export const Footer: FunctionComponent = () => {
  const router = useRouter();
  const path = usePathname();
  const { isMobile } = useScreenSize();

  const { footerItemsV2 } = useNavbarItems();

  const isAgendaPath = path ? path.includes('/agenda') : false;

  if (isAgendaPath) return null;
  return (
    <footer className="bottom-0 w-full">
      <div className="bg-white py-2 text-black">
        <ResponsiveContainer>
          <Row className="justify-center md:justify-between md:pt-10">
            <Column className="items-center sm:items-start" gap={32}>
              {isMobile ? (
                <Logo
                  type="plain"
                  width="80%"
                  height="45px"
                  color={theme.colors.black}
                  onClick={() => router.push('/')}
                  className="cursor-pointer"
                />
              ) : (
                <p className="text-2xl font-bold">SER INDEPENDIENTE PUEDE SER SIMPLE</p>
              )}
              <Row align="center" gap={24}>
                {MEDIA_LINKS.map((name, i) => (
                  <Link key={i} href={name.link} target="_blank">
                    <FontAwesomeIcon icon={name.icon} color="#212121" size="xl" />
                  </Link>
                ))}
              </Row>
            </Column>
            <div className="hidden md:flex">
              <div className="mr-16">
                <span className="font-medium block mb-4">Sobre Ágora</span>
                <div>
                  {footerItemsV2
                    .filter(({ type }) => type == 'about')
                    .map((item) => {
                      const { label, onClick } = item;
                      return (
                        <button key={label} onClick={onClick} className="block mb-2">
                          {label}
                        </button>
                      );
                    })}
                </div>
              </div>
              <div>
                <span className="font-medium block mb-4">Legales</span>
                <div>
                  {footerItemsV2
                    .filter(({ type }) => type == 'legals')
                    .map((item) => {
                      const { label, onClick } = item;
                      return (
                        <button key={label} onClick={onClick} className="block mb-2">
                          {label}
                        </button>
                      );
                    })}
                </div>
              </div>
            </div>
          </Row>
        </ResponsiveContainer>
      </div>
      <div className="hidden md:flex bg-black opacity-80 py-4 text-center text-sm text-white">
        <Wrapper>Ágora © {new Date().getFullYear()} Todos los derechos reservados</Wrapper>
      </div>
    </footer>
  );
};
